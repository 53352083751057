* {
  margin: 0;
  padding: 0;
  font-family: Roboto;
}
html{
  scroll-behavior: smooth;
}
#root {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0
}

body {
  background-color: white;
  height: 100%;
}

.App {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}

.ResultadosContainer {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ResultadosContainerMobile {
  width: 100%;
  position: absolute;
  top: 0px;
}

.ResultadosContainerMobile .FormContainer {
  width: calc(100% - 20px);
  border: none;
  height: 100%;
}

.ResultadosContainerMobile .inputBusca {
  width: calc(100% - 20px);
}

.FormContainer {
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 500px;
  justify-content: space-around;
}

.span {
  font-weight: 300;
  font-size: 22px;
  color: #363636;
  padding: 5px;
  font-style: italic;
}

.ContainerButtonPlaca {
  display: flex;
  justify-content: center;
  width: 100%;
}

.buttonPlaca {
  background-color: #3498db;
  border: none;
  color: white;
  padding: 20px;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  transition: all .2s;
  width: 100%;
  margin-top: 5px;
}
.buttonPlaca svg{
  width: 20px;
  margin-right: 10px;
}
.buttonPlaca:hover {
  opacity: 0.8;
}

.background {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ContainerDesign {
  transform: scaleX(-1);
}

.CarContainer {}

.bordao {
  font-size: 4vw;
  font-weight: 300;
  text-align: end;
}

.ContainerBordao {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-self: flex-start;
  width: 100%;
}

.kondor {
  width: 50%;
  align-self: end;
}

.ContainerPlaca {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

input:focus {
  outline: none;
}

.textTitle {
  font-size: 26px;
  font-weight: 300;
  color: #363636;
}

.ContainerNenhum {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}

.ContainerNenhum p {
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
}

.backgroundDesign {
  /* transform: rotate(0deg) skewX(-25deg); */
  background-image: radial-gradient(#487eb0, #192a56);
  z-index: -10;
  position: absolute;
  width: 60%;
  height: 100%;
  top: 0px;
  left: -20px;
}

.backgroundDesign div {
  height: 100%;
  width: 100%;
  opacity: 0.3;
  background-image: url("http://www.marliassis.com.br/wp-content/themes/mude/images/background-images/polygon.png");
}

.Listrado {
  display: none;
  position: absolute;
  z-index: 0;
  background: repeating-linear-gradient(90deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 20px, black 20px, black 30px);
  width: 700px;
  height: 150px;
  transform: skewX(-20deg);
  opacity: 1;
  left: calc( 10vw - 200px);
  margin-top: 448px;
}

.Header1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: linear-gradient(0deg, #ecf0f1, white);
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: 1;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.Header1 p {
  color: #363636;
}

.subContainerBordao {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
}

.subContainerBordao img {
  width: 70%;
  color: transparent;
}

.Header2, .Header3 {
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-size: cover;
  background-color: #130f40;
  background-color: #130f40;
  background-image: linear-gradient(315deg, #130f40 0%, #000000 74%);
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.Header2{
  
  height: 400px;
  max-height: 400px;
}
.Header3 {
  background-image: linear-gradient(180deg, #ecf0f1, white);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Header3 #row {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}

.Header3 #row2 {
  width: 40%;
}

.Header3 #row3 {
  width: 100%;
  display: flex;
  align-items: center;

}
#row3 #produtos{
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
 
  align-items: center;
  overflow-x: scroll;
  scroll-behavior: smooth;
  min-height: 450px;
  
  -moz-user-select:none;
  user-select: none;
  -webkit-user-select:none;
}
/* Hide scrollbar for Chrome, Safari and Opera */

.Header3 #row3 #produtos::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.Header3 #row3 #produtos {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.Header3 .inputBusca {
  max-width: 100%;
  background-color: transparent;
}

.inputBusca {
  padding: 20px 10px 20px 10px;
  font-size: 22px;
  color: #363636;
  font-weight: 500;
  font-style: italic;
  width: calc(100% - 20px);
  border: none;
  border-bottom: 1px solid #ccc;
}

.inputBusca:focus {
  outline: none;
}
.errorBaterias{
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.errorBateriasText{
  color: #363636;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
}
.errorBateriasButton{
  border: none;
  background-color: #3498db;
  color: white;
  font-size: 18px;
  padding: 10px;
  margin: 10px;
  cursor: pointer;
  border-radius: 3px;
  display: flex;
  align-items: center;
  transition: all .2s;
}
.errorBateriasButton:hover{
  opacity: 0.7;
}
.PainelVeiculos {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 40;
  background-color: white;
  animation: painelVeiculos .5s ;
}

@keyframes painelVeiculos {
  0% {
    opacity: 0;
    top: 300px;
  }
  100% {
    opacity: 1;
    top: 0;
  }
}

#aguarde {
  color: #363636;
  text-align: center;
  padding-top: 10px;
}

#listras {
  height: 700px;
  display: flex;
  transform: skewY(-20deg);
  position: absolute;
  z-index: 0;
  right: 100px;
}

#listras div {
  height: 100%;
  width: 25px;
  margin-left: -1px;
}

#listras div:nth-child(1) {
  background-color: #E3221B;
}

#listras div:nth-child(2) {
  background-color: #E64E0F;
}

#listras div:nth-child(3) {
  background-color: #FFCB00;
}

.Header2 #flex {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  border-radius: 3px;
  width: 40%;
  z-index: 1;
}

#ou {
  text-align: center;
  padding: 10px;
  font-size: 32px;
  color: #363636;
  font-weight: 900;
}

#title {
  color: #fff;
  font-size: 68px;
  max-width: 40%;
  font-weight: 300;
  z-index: 1;
}

.Header3 #title {
  color: #363636;
  text-align: right;
}
.chevron{
  position: absolute;
  padding: 10px;
  cursor: pointer;
  transition: all .2s;
  background-color: #363636 ;
  box-shadow: 5px 5px 18px #888888;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  border-radius: 3px;
  color: white;
}
.chevron:hover{
  opacity: 0.7;
}
.chevron:last-of-type{
  position: absolute;
  right: 0;
}
.Header0{
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
  animation-name: opacity;
  animation-duration: 1s;
  animation-iteration-count: 1;
}
.Header0 img{
  cursor: pointer;
  width: 300px;
  height: 100%;
  color: transparent;
}
.Header0 div{
  display: flex;  
}
.Header0 p{
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: #aaa;
  transition: all .2s ;
  margin-right: 100px;
  border-bottom: 2px solid rgba(0, 0, 0, 0) ;
}
.Header0 p:hover{
  opacity: 0.7;
  color: #363636;
  border-bottom: 2px solid #363636;
}
.containerCarregarMais{
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #3498db;
  min-height: 200px;
  min-width: 200px;
  color: #3498db;
  border-radius: 3px;
  cursor: pointer;
  transition: all .2s;
  font-size: 22px;
  text-align: center;
  margin-right: 20px;
}
.containerCarregarMais:hover{
  background-color: rgba(52, 155, 219,0.2);
}

@media only screen and (max-width: 800px) {
  .Header0 div{
    display: none;  
  }
  .Header0{
    justify-content: center;
  }
  .Header0 img{
    width: 300px;
  }
  #row3 #produtos{
    margin: 0;
    padding: 0px;
  }
  
  .chevron{
    display: none;
  }
  #row3 img{
    width: 60%;
  }
  #listras {
    height: 700px;
    z-index: 0;
    right: 35px;
  }
  #listras div {
    margin-left: -1px;
    width: 15px;
  }
  .Header1 {
    flex-direction: column;
    padding-top: 20px;
  }
  
  .Header1 .bordao {
    justify-content: center;
  }
  .Header2 {
    flex-direction: column;
  }
  .Header2 #flex {
    width: calc(100% - 60px);
    min-height: 350px;
  }
  #title {
    max-width: 100%;
    text-align: center;
    z-index: 1;
    font-size: 9vw;
  }
  .Header2  #title{

    text-shadow: 1px 1px 8px #000;
  }
  .subContainerBordao {
    justify-content: center;
    align-items: center;
  }
  .App {
    flex-direction: column;
  }
  .bordao {
    font-size: 8vw;
    z-index: 1;
  }
  .ResultadosContainer {
    width: calc(100% - 40px);
  }
  .FormContainer {
    border-left: none;
  }
  .ProdutosContainer {
    justify-content: flex-start;
  }
  .backgroundDesign {
    transform: skewY(-7deg);
    left: 0px;
    width: 100%;
    height: 60%;
    top: -100px
  }
  .Listrado {
   display: none;
  }
  .ContainerBordao {
    margin-top: 40px;
  }
  .Header3 #row {
    flex-direction: column-reverse;
    width: 100%;
  }
  .Header3 #row2{
    width: calc(100% - 40px);
  }  
  .Header3 #title{
    text-align: center;
  }
  #title{
    margin-bottom: 40px;
  }
}