* {
  margin: 0;
  padding: 0;
}

.ItemVeiculoContainer {
  background-color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: all .2s;
}

.ItemVeiculoContainerNotHover {
  background-color: #fff;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.ItemVeiculoContainer:hover {
  opacity: 0.5;
}

.ItemVeiculoSubContainer1 {
  min-width: 150px;
  display: flex;
  justify-content: center;
  padding-right: 5px;
  max-width: 150px;
}

.ItemVeiculoSubContainer1 img {
  width: 60px;
  height: 60px;
  transition: all .2s;
  text-align: center;
}

.ItemVeiculoSubContainer2 {
  padding-left: 5px;
  border-left: 1px solid #ccc;
}

.ItemVeiculoModelo {
  font-size: 24;
  font-weight: 500;
  color: #363636;
}

.ItemVeiculoDescricao {
  font-size: 18;
  font-style: italic;
  color: #aaa;
}

.ItemProdutoContainer {
  background-color: white;
  border: 2px solid #eee;
  border-top: 5px solid #3498db;
  min-width: 200px;
  max-width: 200px;
  transition: all .2s;
  border-radius: 3px;
  margin: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.ItemProdutoContainer img {
  color: transparent;
}

.ItemProdutoImg {
  width: 180px;
  height: 135px;
}

.ItemProdutoAH {
  font-weight: 900;
  font-size: 36px;
  text-align: center;
  color: #363636;
}

.ItemProdutoTable {
  width: 100%;
  border-collapse: collapse;
}

.ItemProdutoTable th, .ItemProdutoTable td {
  text-align: left;
  font-size: 18px;
  color: #363636;
  border-bottom: 1px solid #ccc;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 500;
}

.ItemProdutoTable td {
  font-style: italic;
  font-weight: 300;
  text-align: right;
  padding-right: 10px;
}

.ItemProdutoTable th {
  padding-left: 10px;
}

.ItemProdutoButton {
  background-color: #3498db;
  border: none;
  padding: 10px;
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  transition: all .2s;
  min-height: 60px;
}

.ItemProdutoButton:hover {
  opacity: 0.7;
}

.card {
  position: relative;
  min-width: 600px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  animation-name: card;
  animation-duration: 2s;
  animation-iteration-count: 1;
  z-index: 1000;
  transform: translate3d(0px, 0px, 1px);
  -webkit-transform: translate3d(0px, 0px, 1px);
}

.card span {
  position: absolute;
  top: 0px;
  left: calc(100% / 53 * var(--i));
  width: calc(100% / 53);
  height: 100%;
}

.card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  pointer-events: none;
}

.card span:hover+img {
  opacity: 1;
}

.card:not(:hover) div div:nth-child(1) img {
  opacity: 1;
}

.cardMobile {
  display: none;
}

.orbit {
  opacity: 0.1;
  min-height: 600px;
  width: 600px;
  border-radius: 600px;
  transform: rotateX(-74deg);
  margin-top: -400px;
  animation-name: orbit;
  animation-duration: 2s;
  animation-iteration-count: 1;
  border: 10px solid #363636;
  z-index: 999;
  transform: rotate3d(-1, 0, 0, -74deg) translate3d(0px, 0px, 0px);
  -webkit-transform: rotate3d(-1, 0, 0, -74deg) translate3d(0px, 0px, 0px);
}

/* .orbit {
  position: absolute;
  opacity: 0.1;
  height: 445px;
  width: 445px;
  z-index: 0;
  border-radius: 400px;
  transform: rotateX(63deg);
  margin-top: 200px;
  margin-left: -30px;
  animation-name: orbit;
  animation-duration: 2s;
  animation-iteration-count: 1;
  border: 10px solid #363636;
} */

.ContainerKondor360 {
  width: 600px;
  height: 400px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  align-items: center;
  user-select: none;
  -webkit-user-select: none;
  margin-top: 100px;
  margin-bottom: -100px;
}

.ContainerKondor360 img {
  color: transparent;
}

/* .ContainerKondor360 {
  width: 600px;
  height: 400px;
  justify-content: center;
  display: flex;
  align-items: center;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.ContainerKondor360 img {
  color: transparent;
} */

input[type=range] {
  display: none;
}

@keyframes card {
  0% {
    margin-left: -100px;
    opacity: 0;
  }
  40% {
    margin-left: -100px;
    opacity: 0;
  }
  100% {
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes orbit {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0.1;
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 800px) {
  .ItemProdutoImg, #row3 img {
    width: 180px !important;
    height: 135px !important;
  }
  .card {
    min-width: 250px;
    min-height: 160px;

  }
  .card div:nth-child(1) {
    display: none;
  }
  .card div:nth-child(2) {
    display: block;
  }
  .orbit {
    min-height: 290px;
    width: 290px;
    transform: rotateX(77deg);
    margin-top: -196px;
    border: 10px solid #363636;
  }
  .ContainerKondor360 {
    width: 100%;
    height: 300px;
    justify-content: center;
    display: flex;
    margin-top: 0px;
    margin-bottom: -50px;
    align-items: center;
  }
  input[type=range] {
    display: block;
    -webkit-appearance: none;
    width: 100%;
    height: 150px;
    background: #d3d3d3;
    outline: none;
    opacity: 0;
    outline: none;
    position: relative;
    z-index: 1;
  }
  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 1px;
    height: 1px;
    background: #04AA6D;
    cursor: pointer;
    outline: none;
  }
  input[type=range]::-moz-range-thumb {
    background: #04AA6D;
    cursor: pointer;
    outline: none;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]:focus::-webkit-slider-thumb {
    outline: none;
  }
  input[type=range]:focus::-moz-range-thumb {
    outline: none;
  }
}