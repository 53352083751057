.BateriasResultContainer{
  width: 100%;
  position: absolute;
  z-index: 10;
  height: 100%;
  top: 0px;
  background-color: white;
}

.headerContainer{
  height: 86px;
  background-color: white;
  border-bottom: 1px solid #ccc;
}

.BodyContainer{
  background-color: white;
  height: 100%;
  animation: init .5s ;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow-y: auto;
  top:0px;
}
@keyframes init {
  from {top: 300px;opacity: 0;}
  to {top: 0px;opacity: 1;}
}
.InfoSelecionado{
  padding: 20px;
  display: flex;
  align-items: center;
}
.ProdutosContainer{
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 300px;
  overflow-x: scroll;
  scrollbar-width: none;
}
.InfoSelecionadoSubContainer1{
  padding-right: 5px;
}
.InfoSelecionadoSubContainer1 img{
  width: 60px;
}
.InfoSelecionadoSubContainer2{
  padding-left: 5px;
  border-left: 1px solid #ccc;
}
.InfoSelecionadoModelo{
  color: #363636;
  font-size: 20px;
}
.InfoSelecionadoDescricao{
  font-weight: 300;
  font-style: italic;
  color: #aaa;
  font-size: 22px;
}

.BodyContainerCompativeis{
  height: 100%;
  background-color: white;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
}
.CompativeisContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.InfoBateriaSelecionadaImg{
  width: 150px;
  color: transparent;
}
.InfoBateriaSelecionada{
  display: flex;
  align-items: center;
}
.InfoBateriaSelecionadaSubContainer1{
  padding-right: 5px;
}
.InfoBateriaSelecionadaSubContainer2{
  padding-left: 5px;
  border-left: 1px solid #bbb;
}
.InfoBateriaSelecionadaAH{
  color: #363636;
  font-size: 20px;
}
.InfoBateriaSelecionadaReferencia{
  font-weight: 300;
  font-style: italic;
  color: #aaa;
  font-size: 22px;
}

.ContainerVoltar{
  cursor: pointer;
  padding: 10px;
  transition: all .2s;
  font-weight: 300;
  font-size: 22px;
  display: flex;
  align-items: center;
  width: 100px;
  color: #363636;
}
.ContainerVoltar:hover{
  opacity: 0.8;
}

.inputPlaca {
  padding: 20px 10px 20px 10px;
  font-size: 22px;
  color: #363636;
  min-width: 460px;
  max-width: calc(100% - 20px);
  width: calc(100% - 20px);
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 22px;
  color: #363636;
  font-weight: 500;
  font-style: italic;
}

.ContainerNenhum{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.ContainerNenhum p{
  font-size: 22px;
  font-weight: 300;
  font-style: italic;
}
.ContainerPesquisa{
  display: flex;
  flex-direction: column;
}
.ContainerPesquisa div{
  display: flex;
  align-items: center;
}
.span {
  font-weight: 300;
  font-size: 22px;
  color: #363636;
  padding: 5px;
  font-style: italic;
}

@media only screen and (max-width: 800px) {
  .ProdutosContainer{
    justify-content: flex-start;
  }
  .ContainerPesquisa .inputPlaca{
    min-width: 0px;
  }
  .ContainerPesquisa svg{
    padding: 10px !important;
    width: 25px;
  }
 }
